<template>
    <div class="mainbox_question">
        <div class="bc">
            <div class="ttil">
                <div @click="goback()" class="goback">
                    <i class="el-icon-arrow-left"></i> 返回
                </div> 收藏夹
            </div>
            <div class="collect">
                <div class="table">

                    <div class="theader" style="padding-left: 44px">
                        <span>题型</span>
                        <span style="margin-right: 246px">收藏数量</span>
                        <span>操作</span>
                    </div>
                    <!-- <div class="tbody"> -->
                    <div class="tcontent">
                        <div class="t-item" v-for="item in list" :key="item.questionType">
                            <template v-if="item.questionType == 0">
                                <img src="@/assets/img/questionNew/15.png" class="t-item-icon" />
                                <span class="t-item-name">单选题</span>
                            </template>
                            <template v-if="item.questionType == 1">
                                <img src="@/assets/img/questionNew/21.png" class="t-item-icon" />
                                <span class="t-item-name">多选题</span>
                            </template>
                            <template v-if="item.questionType == 2">
                                <img src="@/assets/img/questionNew/22.png" class="t-item-icon" />
                                <span class="t-item-name">判断题</span>
                            </template>
                            <template v-if="item.questionType == 6">
                                <img src="@/assets/img/questionNew/16.png" class="t-item-icon" />
                                <span class="t-item-name t-item-name-i">不定项题</span>
                            </template>
                            <template v-if="item.questionType == 3">
                                <img src="@/assets/img/questionNew/18.png" class="t-item-icon" />
                                <span class="t-item-name">填空题</span>
                            </template>
                            <template v-if="item.questionType == 4">
                                <img src="@/assets/img/questionNew/17.png" class="t-item-icon" />
                                <span class="t-item-name">简答题</span>
                            </template>
                            <template v-if="item.questionType == 5">
                                <img src="@/assets/img/questionNew/20.png" class="t-item-icon" />
                                <span class="t-item-name">组合题</span>
                            </template>
                            <div class="t-item-number">
                                <div class="t-item-num-i">
                                    <span>共</span>
                                    <span>{{ item.dataNum }}</span>
                                    <span>道</span>
                                </div>
                            </div>
                            <div class="correct-but" @click="start(item, 0)">刷题</div>
                            <div class="correct-but correct-but2" @click="start(item, 1)">背题</div>
                        </div>
                        <div class="noData" v-if="list.length == 0">暂无数据</div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import Vue from 'vue'
import { getInfo } from '@/api/cookies'
import { AnswerClient } from '@/api/task/answerClient'
const answerClient = new AnswerClient()
export default {

    components: {},
    data() {
        return {
            courseNameList: [], // 试卷列表
            info: {},
            getInfo: null,
            makerType: '',
            courseNameId: '',
            clearAllShow: false,
            clearShow: false,
            screenShow: false,
            ycItem: {},
            list: [],


        }
    },
    async created() {
        this.userInfo = getInfo()
        if (!this.userInfo) {
            Vue.prototype.goLoginView(false)
            return
        }
        this.courseNameId = this.$route.query.courseNameId
        this.makerType = this.$route.query.makerType

        await this.getMakerTypeSubject()

    },
    methods: {


        async getMakerTypeSubject() {
            answerClient.getMakerTypeSubject({ courseId: this.courseNameId, makerType: this.makerType }).then(async res => {
                this.list = res.data

            })
        },

        // 消灭错题
        start(item, index) {
            sessionStorage.setItem("fallibleTips", JSON.stringify(item))
            this.$router.push({
                path: "/questionNew/questionAnswerOther",
                query: {
                    courseNameId: this.courseNameId,
                    questionType: item.questionType,
                    makerType: 2,
                    type: 4,
                    xmtype: 1,
                    questionTypeName: item.questionTypeName,
                    isbeiTi: index
                },
            });
        },
        goback() {
            this.$router.go(-1)
        }


    }
}
</script>
<style lang="less" scoped>
.mainbox_question {
    width: 100%;
    background-image: url("~@/assets/img/questionNew/32.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding: 24px 28px 0;
    border-radius: 8px;
    padding-bottom: 20px;
}

.theader {
    // box-shadow: 0px 3px 6px 1px #FFE2DF;
}

.table {
    box-shadow: 0px 0px 12px 1px rgba(204, 204, 204, 0.3);
    border-radius: 12px;

    .theader {
        height: 58px;
        background-color: #FFE2DF;
        display: flex;
        align-items: center;
        padding-left: 34px;
        font-size: 16px;
        color: #333;
        font-weight: 600;
        border-radius: 12px 12px 0 0;
        position: relative;

        span:nth-child(1) {
            margin-right: 164px;
        }

        span:nth-child(2) {
            margin-right: 320px;
        }
    }

    .tbody {
        border-radius: 0 0 12px 12px;
        background-color: #fff;
        padding: 16px 14px 14px;
    }

    .tcontent {
        background-color: #FEF4F4;
        border-radius: 8px;
        padding: 0 20px;
        margin-top: 10px;

        .t-item {
            padding: 16px 0;
            border-bottom: 1px solid #eee;
            display: flex;
            align-items: center;

            &:last-child {
                border-bottom: none;
            }

            .t-item-icon {
                width: 46px;
                height: 46px;
                margin-right: 16px;
            }

            .t-item-name {
                font-size: 16px;
                color: #333;
                margin-right: 70px;
                display: block;
                min-width: 65px;
            }

            .t-item-number {
                flex: 1;

                .t-item-num-i {
                    display: inline-block;
                    margin-right: 20px;
                    font-size: 15px;
                    color: #333;

                    span {
                        &:nth-child(1) {
                            margin-right: 5px;
                        }

                        &:nth-child(2) {
                            color: #FF5D51;
                        }
                    }
                }
            }

            .t-item-but {
                width: 100px;
                height: 36px;
                border-radius: 18px;
                background-color: #FF5D51;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 15px;
                color: #fff;
                cursor: pointer;
            }

            .t-item-but-2 {
                border: 1px solid #FF5D51;
                color: #FF5D51;
                background-color: transparent;
            }
        }
    }
}

.noData {
    text-align: center;
    padding: 20px 0;
    color: #909399;
}

.collect {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    // padding: 24px 32px;
    padding-bottom: 30px;

    .correct-but {
        width: 100px;
        height: 36px;
        border-radius: 18px;
        text-align: center;
        line-height: 36px;
        font-size: 15px;
        color: #fff;
        margin-left: 16px;
        background: linear-gradient(360deg, #FF5D51 0%, #FF5D51 100%);
        cursor: pointer;
    }

    .correct-but2 {
        background: linear-gradient(180deg, #ffcb97 0%, #ff9e30 100%);
    }
}

.tbody {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.tcontent {
    padding: 0 24px !important;
}

.bc {
    background: #fff;
    padding: 16px;
    border-radius: 8px;

    .ttil {
        position: relative;
        text-align: center;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 20px;
        color: #333333;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .goback {
        position: absolute;


        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #777777;
        top: 2px;
        cursor: pointer;
    }
}
</style>
